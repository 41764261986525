<template>
    <div>
        <p class="title_form__header">Укажите заголовок</p>
        <input type="text" name="firstName" id="header" v-model="problem.header">
        <p class="title_form">Описание проблемы</p>
        <div class="contain">
            <div class="contain__element">
                <p>Опишите суть проблемы<span>*</span></p>
                <textarea name="description" id="description" placeholder="Описание" maxlength='5000' v-model="problem.description"
                @keyup="clickTextarea(problem.description) " :class="{'active-classe':activeClass && problem.description ===''}">
                </textarea>
            </div>
            <div class="contain__element loading">
                <p>Фотографии</p>
                <div v-show="problem.valueFile.length !== 0" class="img_block">
                </div>
                <div class="block_file" :class="{'loader_file': problem.valueFile.length !== 0}">
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="vfileAdded"
                      :useCustomSlot="true" :value="problem.valueFile" :include-styling="false"
                      @vdropzone-removed-file="removedFile" @vdropzone-thumbnail="thumbnail">
                        <div class="admin_dropzone">
                            <div></div>
                            <p>Перетащите файлы с компьютера или нажмите кнопку загрузить</p>
                            <span> (форматы png, jpg, pdf)</span>
                            <button>Загрузить</button>
                        </div>
                    </vue-dropzone>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  name: 'ProblemDescriptionAppeal',
  props: {
    activeClass: {
      type: Boolean,
      define: false
    }
  },
  components: {
    'vue-dropzone': vue2Dropzone
  },
  data () {
    return {
      number: 5000,
      problem: {
        header: '',
        description: '',
        valueFile: []
      },
      dropzoneOptions: {
        url: '/api/upload-file',
        thumbnailWidth: 150,
        maxFilesize: 1,
        maxFiles: 10,
        addRemoveLinks: true,
        parallelUploads: 1,
        headers: { 'Content-Type': 'multipart/form-data' },
        paramName: 'file',
        previewTemplate: this.template(),
        accept: function (file, done) {
          if (file.name !== 0) {
            // var NameFile = file.name
          } else {
            done()
          }
        }
      }
    }
  },
  computed: {
    valueInput () {
      return (this.problem.header || this.problem.description || this.problem.valueFile)
    }
  },
  watch: {
    valueInput () {
      this.$emit('problemInput', this.problem)
    }
  },
  methods: {
    clickTextarea (event) {
      this.number = 5000 - event.length
    },
    vfileAdded (file) {
      var files = this.problem.valueFile
      files.push(file)
      this.problem.valueFile = files
    },
    removedFile (file) {
      var array = this.problem.valueFile
      var newArr = []
      array.forEach(item => {
        if (file.upload.uuid !== item.upload.uuid) {
          newArr.push(item)
        }
      })
      this.problem.valueFile = newArr
    },
    template () {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                </div>
            </div> `
    },
    thumbnail (file, dataUrl) {
      if (file.previewElement) {
        file.previewElement.classList.remove('dz-file-preview')
        file.previewElement.querySelector('.dz-image').style.backgroundImage = `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${dataUrl}')`
        var drop, parents
        parents = document.querySelector('.img_block')
        drop = document.getElementById('dropzone')
        for (var i = 1; i < drop.children.length; i++) {
          parents.insertBefore(drop.children[i], null)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.title_form__header{
    margin-bottom: 0.5625rem;
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.38rem;
}
#header{
    width: 100%;
    height: 2.1875rem;
    padding: 0 .75rem;
    margin-bottom: 3.5rem;
    background: #EEF4FA;
    border-radius: .19rem;

    font-weight: 600;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #1F3245;
}
#header[placeholder]{
    font-weight: normal !important;
    color: #a89a5a85 !important;
}
#header:focus{
    background: #DDEDFE;
}

.title_form{
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #333333;
    margin-bottom: 1rem;
}

.contain{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.125rem;
    &__element{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 55.3rem;
        margin-right: 2.19rem;
        p{
            font-size: .875rem;
            line-height: 1.375rem;
            color: #1F3245;
            margin-bottom: .56rem;
        }
        span{
            color: #EB494E;
        }
        textarea{
            width: 100%;
            height: 30.75rem;
            padding: 1rem 1.125rem;
            background: #EEF4FA;
            border: 0;
            border-radius: .19rem;
            font-weight: 600;
            font-size: .875rem;
            line-height: 1.38rem;
            color: #1F3245;
        }
        textarea::placeholder{
            font-family: "Open Sans", Arial, sans-serif;
            color: #003E78;
            font-weight: normal;
            opacity: 0.55;
        }
        // loading.svg
        input{
            width: 100%;
            height: 2.1875rem;
            padding: 0 .75rem;
            background: #EEF4FA;
            border-radius: .19rem;

            font-weight: 600;
            font-size: .875rem;
            line-height: 1.38rem;
            color: #1F3245;
        }
        input::placeholder{
            font-weight: normal !important;
            color: #5A79A8 !important;
            opacity: 0.55;
        }
        input:focus{
            background: #DDEDFE;
        }
    }
    .loading{
        width: 26.56rem;
        margin: 0;
    }
}
.info_block p{
    font-size: .75rem;
    line-height: 1.125rem;
    color: #333333;
    margin-bottom: 0;
}
.error__show{
    display: flex;
    margin-bottom: 1.74rem;
    font-weight: 600;
    font-size: .813rem;
    line-height: 1.25rem;
    color: #EB494E;
    svg{
        width: 2rem;
        height: 2rem;
    }
    p{
        padding: .125rem 0 0 .38rem;
    }
}
.active-classe{
    border: .06rem solid #EB494E !important;
    box-sizing: border-box;
}
.block_file{
    height: 30.75rem;
    border: .125rem dashed #95b0c7;
    border-radius: .19rem;
    padding: .625rem;
}
#dropzone{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: .19rem;
    background: #EEF4FA;
    transition: background-color .2s linear;
    overflow-y: hidden;

    .admin_dropzone{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 17rem;
        font-family: "Open Sans";
        text-align: center;
        div{
            width: 2.5rem;
            height: 2.5rem;
            background: url('../../assets/icons/dropzone.svg') center center no-repeat;
            background-size: contain;
            opacity: 0.3;
        }
        p{
            font-size: .75rem;
            line-height: 1.13rem;
            color: #333333;
            margin: 1.063rem 0 .88rem 0;
        }
        span{
            font-size: .88rem;
            line-height: 1.13rem;
            color: #5E686C !important;
        }
        button{
            margin-top: 1.25rem;
            font-weight: 600;
            font-size: .88rem;
            line-height: 1.25rem;
            color: #FFFFFF;
            padding: .5rem 2.75rem;
            background: #246CB7;
            box-shadow: 0 .38rem 1.25rem rgba(36, 108, 183, 0.35);
            border-radius: .19rem;
            border: 0;
        }
    }
}
.loader_file{
  height: 22.19rem;
}

  .img_block{
    padding: 0.8rem 0 .3rem 0;
    margin: auto 0 1.5rem 0;
    display: flex;
    overflow-y: auto;
    .dz-preview {
        width: 9.19rem;
        display: inline-block;
        position: relative;
        margin-right: 1rem;
        .dz-image {
            width: 9.19rem;
            height: 5.125rem;
            border-radius: .19rem;
        }
      }
      .dz-remove{
        width: 1.44rem;
        height: 1.44rem;
        position: absolute;
        top: -0.8rem;
        right: -0.67rem;

        background:  #ffffff url('../../assets/icons/close.svg') center center no-repeat;
        background-size: .45rem;
        border-radius: 50%;
        overflow: hidden;
        color: transparent;
        font-size: 0
      }
  }
#dropzone .dz-remove{
  display: none;
}

::-webkit-scrollbar {
    width: .0rem;
    height: .3rem;
}
.img_block::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: .19rem;
    border-radius: .19rem;
}
.img_block::-webkit-scrollbar-thumb {
    -webkit-border-radius: .19rem;
    border-radius: .19rem;
    background: rgba(143, 143, 143, 0.8);
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.img_block::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(143, 143, 143, 0.8);
}
</style>
