<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>Обращения граждан</h5>
            <div class="radio__button">
                <div class="step">
                    <div :class="{'active__block': classNum>=0}"><span>1</span></div>
                    <p v-show="classNum===0">Контактные данные </p>
                    <span v-show="classNum===0" class="step__line"> </span>
                </div>
                <div class="step">
                    <div :class="{'active__block': classNum>=1}"><span>2</span></div>
                    <p v-show="classNum===1">Описание проблемы</p>
                    <span v-show="classNum===1" class="step__line"> </span>
                </div>
                <div class="step">
                    <div :class="{'active__block': classNum>=2}"><span>3</span></div>
                    <p v-show="classNum===2">Адрес где выявлена проблема</p>
                    <span v-show="classNum===2" class="step__line"> </span>
                </div>
                <div class="step">
                    <div :class="{'active__block': classNum >=3}"><span>4</span></div>
                    <p v-show="classNum===3">Завершение подачи обращения</p>
                    <span v-show="classNum===3" class="step__line"> </span>
                </div>
            </div>
            <!--  -->
            <personal-data-appeal v-show="classNum===0" @personalData="personalDataInput" :activeClass="showError"/>
            <problem-description-appeal v-show="classNum===1" @problemInput="problemInput" :activeClass="showError"/>
            <address-problem-appeal v-show="classNum===2" @addressData="addressDataInput" :activeClass="showError" :keyNum="classNum"/>
            <finishing-appeal v-show="classNum===3"  @finishData="finishDataInput" :activeClass="showError"/>
            <!--  -->
            <div class="error__show" v-show="showError">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.0442 4.05398C14.2199 2.17878 16.952 2.17878 18.1277 4.05398L24.7172 14.564C25.9699 16.562 24.5337 19.1576 22.1754 19.1576L8.99644 19.1576C6.63823 19.1576 5.20201 16.562 6.4547 14.564L13.0442 4.05398Z" fill="#EB494E"/>
                    <path d="M16.939 12.7246H14.8442L14.5205 6.71973H17.2627L16.939 12.7246ZM14.5459 14.9463C14.5459 14.5612 14.6602 14.2629 14.8887 14.0513C15.1172 13.8354 15.4494 13.7275 15.8853 13.7275C16.3169 13.7275 16.6427 13.8354 16.8628 14.0513C17.0871 14.2629 17.1992 14.5612 17.1992 14.9463C17.1992 15.3271 17.0828 15.6255 16.8501 15.8413C16.6216 16.0529 16.3 16.1587 15.8853 16.1587C15.4663 16.1587 15.1383 16.0529 14.9014 15.8413C14.6644 15.6255 14.5459 15.3271 14.5459 14.9463Z" fill="white"/>
                </svg>
                <p>Заполните все необходимые поля</p>
            </div>
            <div class="checkbox_block" v-if="classNum>=3">
                  <input class="custom-checkbox" type="checkbox" id="assent" v-model="checkboxValue" :class="{'box__active': checkboxValue}">
                  <label for="assent"></label>
                <p>Согласие обработки персональных данных “в соответствии с требованиями Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных данных»”</p>
            </div>
            <div class="contain__button" :class="{'active_border': classNum>=3}">
                <div class="contain__button__block">
                    <p class="cancel" @click="showTab(-1)">Отмена</p>
                    <p class="footnote">Поля помеченные <span>*</span> являются обязательными для заполнения</p>
                </div>
                <button @click="nextTab(1)" v-if="classNum<3" :class="{'next_color': nextButton}">Далее</button>
                <button v-else>Отправить</button>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import PersonalDataAppeal from '@/components/appeal/PersonalDataAppeal'
import ProblemDescriptionAppeal from '@/components/appeal/ProblemDescriptionAppeal'
import AddressProblemAppeal from '@/components/appeal/AddressProblemAppeal'
import FinishingAppeal from '@/components/appeal/FinishingAppeal'

export default {
  name: 'AppealForms',
  components: {
    'bread-crumb': BreadCrumb,
    'personal-data-appeal': PersonalDataAppeal,
    'problem-description-appeal': ProblemDescriptionAppeal,
    'address-problem-appeal': AddressProblemAppeal,
    'finishing-appeal': FinishingAppeal
  },
  mounted () {
    this.fixStepIndicator(0)
  },
  data () {
    return {
      nextButton: false,
      checkboxValue: false,
      showError: false,
      classNum: 0,
      personalInfo: {
        phone: '',
        firstName: '',
        lastName: '',
        patronymic: '',
        email: ''
      },
      address: {
        district: '',
        region: '',
        city: '',
        street: '',
        house: '',
        body: '',
        structure: '',
        flat: '',
        coordinates: ''
      },
      problemData: {
        header: '',
        description: '',
        valueFile: ''
      },
      finishData: {
        category: '',
        members: '',
        email: '',
        phone: '',
        openValue: ''
      },
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Обращения граждан'
        }
      ]
    }
  },
  methods: {
    personalDataInput (event) {
      this.personalInfo = event
    },
    addressDataInput (event) {
      this.addressData = event
    },
    problemInput (event) {
      this.problemData = event
    },
    finishDataInput (event) {
      this.finishData = event
    },
    nextTab (n) {
      if (this.classNum === 0) {
        if (this.personalInfo.phone === '' || this.personalInfo.firstName === '' || this.personalInfo.lastName === '' ||
        this.personalInfo.patronymic === '' || this.personalInfo.email === '') {
          this.showError = true
        } else {
          this.nextButton = true
          this.showError = false
          this.showTab(n)
        }
      } else if (this.classNum === 1) {
        if (this.problemData.description === '') { // this.problemData.header === '' ||
          this.showError = true
        } else {
          this.nextButton = true
          this.showError = false
          this.showTab(n)
        }
      } else if (this.classNum === 2) {
        if (this.addressData.district === '' || this.addressData.city === '' || this.addressData.street === '' ||
            this.addressData.house === '') {
          this.showError = true
        } else {
          this.nextButton = true
          this.showError = false
          this.showTab(n)
        }
      } else if (this.classNum === 3) {
        if (this.finishData.category === '') {
          this.showError = true
        } else {
          this.nextButton = true
          this.showError = false
          this.showTab(n)
        }
      }
    },
    showTab (n) {
      if (n === -1 && this.classNum === 0) {
      } else {
        this.nextButton = false
        this.classNum = this.classNum + n
        this.fixStepIndicator(this.classNum)
      }
    },
    fixStepIndicator (n) {
      var x = document.getElementsByClassName('step')
      for (let i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(' active', '')
      }
      x[n].className += ' active'
    }
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.81rem;
}
input{
    font-family: "Open Sans", Arial, sans-serif;
}
.title_form{
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #333333;
    margin-bottom: 1.125rem;
}

.contain{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.125rem;
    &__button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: .38rem;
        padding-top: 2.5rem;
        border-top: .063rem solid #D5D6D9;
        &__block{
            display: flex;
            align-items: center;
            .cancel{
                width: 10rem;
                padding: .5rem 0 .44rem 0;
                margin-right: 1.375rem;
                text-align: center;
                font-size: .875rem;
                line-height: 1.25rem;
                color: #246CB7;
                border: .063rem solid #246CB7;
                box-sizing: border-box;
                filter: drop-shadow(0px .375rem 1.25rem rgba(36, 108, 183, 0.35));
                border-radius: .19rem;
                cursor: pointer;
            }
            .footnote{
                font-size: .75rem;
                line-height: 1.125rem;
                color: #333333;
                span{
                    color: #EB494E;
                }
            }
        }
        button{
            width: 10rem;
            padding: .5rem 0 .44rem 0;
            font-weight: 600;
            font-size: .875rem;
            line-height: 1.25rem;
            text-align: center;
            color: #ffffff;
            background: #246CB7;
            border-radius: .19rem;
            opacity: 0.5;
            cursor: pointer;
        }
    }
}
.info_block p{
    font-size: .75rem;
    line-height: 1.125rem;
    color: #333333;
    margin-bottom: 0;
}
.error__show{
    display: flex;
    margin-bottom: 1.74rem;
    font-weight: 600;
    font-size: .813rem;
    line-height: 1.25rem;
    color: #EB494E;
    svg{
        width: 2rem;
        height: 2rem;
    }
    p{
        padding: .125rem 0 0 .38rem;
    }
}

.radio__button{
    display: flex;
    align-items: center;
    margin: 0 -0.625rem 2.06rem -0.625rem;
}
.step {
    cursor: pointer;
    margin: 0 0.625rem;
    border-radius: 50%;
    //
    div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.9rem;
        height: 3.9rem;
        border: .125rem solid #D6E6F5;
        border-radius: 50%;
        font-weight: bold;
        font-size: 1.38rem;
        line-height: 2rem;
        color: #5A79A8;
        >span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.875rem;
            height: 2.875rem;
            background: #FFFFFF;
            box-shadow: 0px 0px 1.38rem rgba(152, 196, 242, 0.33);
            border-radius: 50%;
        }
    }
    p{
        padding-left: .625rem;
        font-weight: bold;
        font-size: 1rem;
        line-height: 2rem;
        color: #1F3245;
    }
    .step__line{
        margin-left: 1.56rem;
        flex: auto;
        height: .063rem;
        background: #D6E6F5;
        border-radius: .063rem;
    }
}
.step.active {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}
.active__block{
    flex: 0 0 3.9rem;
    color: #246CB7;
    background: url('../../assets/icons/radio_button.svg') center center no-repeat;
    background-size: 5.2rem;
    border: 0 !important;
  }
.active_border{
    border: 0;
}
.checkbox_block{
    display: flex;
    margin-top: 2.5rem;
    p{
        font-size: .75rem;
        line-height: 1.125rem;
        color: #1F3245;
        margin-left: .69rem;
    }
    label{
        margin-bottom: 0;
    }
}

.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: auto !important;
  }
  .custom-checkbox + label::before {
    content: '';
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: .19rem;
    background-color: #DDEDFE;
    cursor: pointer;
  }
  .box__active + label::before{
    background: #DDEDFE url("../../assets/icons/check.svg") center center no-repeat;
    background-size: 80%;
}
.next_color{
  opacity: 1;
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .contain .containet_block {
      width: 100%;
    }
}

</style>
