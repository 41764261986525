<template>
    <div>
        <p class="title_form one_title">Категория</p>
        <div class="contain">
            <div class="contain__element">
                <p>Выберите категорию проблемы<span>*</span></p>
                <div class="contain__element__block">
                    <custom-select :key="`unit`" class="select" :list='getCategoryList' @InputsContent="setCategory" placeholder="Выберите из списка" :class="{'active-classe': activeClass && finish.category ===''}"/>
                    <span>Выбор категории поможет ускорить решение проблемы</span>
                </div>
            </div>
            <div class="contain__element">
                <p>Выбрать члена общественной палаты</p>
                <div class="contain__element__block">
                    <custom-select :key="`unit`" class="select" :list='getMembersList' @InputsContent="setMembers" placeholder="Выберите из списка" castom="Все"/>
                    <span>Выбор члена ОПРФ - если Вы хотите обратиться к конкретному члену ОПРФ</span>
                </div>
            </div>
        </div>
        <!--  -->
        <p class="title_form">Куда вам отправлять уведомления?</p>
        <p class="subtitle_form">На email или телефон будут поступать уведомления при изменении статуса обращения.</p>
        <div class="contain start__input">
            <div class="contain__element">
                <p>Email</p>
                <input type="text" name="emailOptionally" id="emailOptionally" v-model="finish.email">
            </div>
            <div class="contain__element">
                <p>Телефон</p>
                <input type="text" name="phoneOptionally" id="phoneOptionally" v-model="finish.phone">
            </div>
        </div>
        <!--  -->
        <p class="title_form">Публичность вашего обращения</p>
        <p class="subtitle_form">Для соответствия требованиям ФЗ "О порядке рассмотрения обращений граждан Российской Федерации" от 02.05.2006 № 59-ФЗ</p>
        <div class="icon_title border_radio_block">
            <label class="v-all v-css">
                <input name="value1" id="value1" type="radio" value="value1" v-model="finish.openValue">
                <s for="value1"></s> <span>Опубликовать мое обращение на сайте публично, для ознакомления и поддержки другими гражданами</span>
            </label>
            <label class="v-all v-css">
                <input name="value2" id="value2" type="radio" value="value2" v-model="finish.openValue">
                <s for="value2"></s> <span>Опубликовать мое обращение без указания ФИО</span>
            </label>
            <label class="v-all v-css">
                <input name="value3" id="value3" type="radio" value="value3" v-model="finish.openValue">
                <s for="value3"></s> <span>Не публиковать мое обращение на сайте для публичного ознакомления</span>
            </label>
        </div>
    </div>
</template>
<script>
import CustomSelect from '@/components/forms/CustomSelect'

export default {
  name: 'FinishingAppeal',
  components: {
    'custom-select': CustomSelect
  },
  props: {
    activeClass: {
      type: Boolean,
      define: false
    }
  },
  data () {
    return {
      finish: {
        category: '',
        members: '',
        email: '',
        phone: '',
        openValue: 'value1'
      },
      getCategoryList: [
        {
          title: 'Образование и наука'
        },
        {
          title: 'ЖКХ, строительство и дороги'
        },
        {
          title: 'Экология'
        }
      ],
      getMembersList: [
        {
          title: 'Алиев Фирдус Завидович'
        },
        {
          title: 'Амелькин Дмитрий Алексеевич'
        }
      ]
    }
  },
  computed: {
    valueInput () {
      return (this.finish.category || this.finish.members || this.finish.email || this.finish.phone || this.finish.openValue)
    }
  },
  watch: {
    valueInput () {
      this.$emit('finishData', this.finish)
    }
  },
  methods: {
    setCategory (value) {
      this.finish.category = value.title
    },
    setMembers (value) {
      this.finish.members = value.title
    }
  }
}
</script>
<style lang="scss" scoped>

.title_form{
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #333333;
}
.one_title{
    margin-bottom: 1.125rem;
}
.subtitle_form{
    font-style: italic;
    font-weight: normal;
    font-size: .81rem;
    line-height: 1.25rem;
    color: #5A79A8;
    margin: .187rem 0 1.125rem 0;
}

.contain{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4.125rem;
    &__element{
        display: flex;
        flex-direction: column;
        margin-right: 2.19rem;
        p{
            font-size: .875rem;
            line-height: 1.375rem;
            color: #1F3245;
            margin-bottom: .56rem;
        }
        span{
            color: #EB494E;
        }
        input{
            width: 100%;
            height: 2.1875rem;
            padding: 0 .75rem;
            background: #EEF4FA;
            border-radius: .19rem;

            font-weight: 600;
            font-size: .875rem;
            line-height: 1.38rem;
            color: #1F3245;
        }
        input::placeholder{
            font-weight: normal !important;
            color: #003E78;
            opacity: 0.6;
            opacity: 0.55;
        }
        input:focus{
            background: #DDEDFE;
        }
        &__block{
            display: flex;

            font-weight: normal;
            font-size: .875rem;
            line-height: 1.25rem;
            color: #5A79A8;
            span{
                width: 17.88rem;
                margin-left: 2.063rem;
                font-style: italic;
                color: #5A79A8;
            }
        }
    }
    &__element:nth-child(2){
        margin-right: 0;
    }
}
.start__input{
    justify-content: flex-start;
    >.contain__element{
        width: 19.44rem;
    }
}
.select, input{
    width: 19.44rem;
}

.v-all {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-size: 1rem;
        line-height: 1.25;
        margin: 1.625rem 1.5rem 1.44rem 0;
        > * {
            flex: 0 1 auto;
        }
        input {
            margin-right: 4px;
        }
        s{
            margin-right: .5rem;
        }
        span{
            font-size: .75rem;
            line-height: 1.125rem;
            color: #1F3245;
        }
    }
    .v-css > input#value1 + s, .v-css > input#value2 + s,
    .v-css > input#value3 + s{
        color: #0091ea;
        cursor: pointer;
        background: #DDEDFE;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 50%;
        background: #DDEDFE;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .v-css > input:checked + s::before {
        background-color: #246CB7;
        z-index: 10000;
    }
    .v-css > input:disabled + s::before { opacity: 0.5; }
    .v-css > input[type="radio"] + s::before {
        border-radius: 50%;
        border: 0;
    }
    .v-css > s::before {
        width: .63rem;
        height: .63rem;
        background-clip: content-box;
        content: "";
        display: inline-block;
    }
    #value1,#value2,#value3{ display: none;}
    .border_radio_block{
        border-bottom: .063rem solid rgba(0, 0, 0, 0.12);
        padding-bottom: 2.68rem;
    }
</style>
