<template>
    <div>
        <p class="title_form">Ваши личные данные</p>
        <div class="contain">
            <div class="contain__element">
                <p>Имя<span>*</span></p>
                <input type="text" name="firstName" id="firstName" v-model="personalData.firstName" :class="{'active-classe': (activeClass && (personalData.firstName ===''))}">
            </div>
                <div class="contain__element">
                <p>Фамилия<span>*</span></p>
                <input type="text" name="lastName" id="lastName" v-model="personalData.lastName" :class="{'active-classe':activeClass && personalData.lastName ===''}">
            </div>
                <div class="contain__element">
                <p>Отчество<span>*</span></p>
                <input type="text" name="patronymic" id="patronymic" v-model="personalData.patronymic" :class="{'active-classe':activeClass && personalData.patronymic ===''}">
            </div>
        </div>
        <p class="title_form">Контактные данные</p>
        <div class="contain">
            <div class="contain__element">
                <p>Телефон<span>*</span></p>
                <input type="tel" name="phone" id="phone" placeholder="+7 ___-___-__-__" pattern="+7-[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}"
                    v-model="personalData.phone" :class="{'active-classe':activeClass && personalData.phone ===''}">
            </div>
                <div class="contain__element">
                <p>Email<span>*</span></p>
                <input type="email" name="email" id="email" v-model="personalData.email" :class="{'active-classe':activeClass && personalData.email ===''}">
            </div>
                <div class="contain__element info_block">
                <p>Телефон и email необходимы для оперативной связи с случае необходимости</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'PersonalDataAppeal',
  props: {
    activeClass: {
      type: Boolean,
      define: false
    }
  },
  data () {
    return {
      personalData: {
        phone: '',
        firstName: '',
        lastName: '',
        patronymic: '',
        email: ''
      }
    }
  },
  computed: {
    valueInput () {
      return (this.personalData.phone || this.personalData.firstName || this.personalData.lastName || this.personalData.patronymic || this.personalData.email)
    }
  },
  watch: {
    valueInput () {
      this.$emit('personalData', this.personalData)
    }
  }
  //   computed: {
//     phoneMask () {
//       return this.phone
//     }
//   },
//   watch: {
//     phoneMask () {
//       var num = this.phone//  console.log(num.match((/^\+?([0-9]{3}){1,2}([0-9]{4})$/) || []).join('-'))
//       var x = num.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
//       // num = !x[2] ? x[1] : '(' + x[1] + ')' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '')
//       num = !x[2] ? x[1] : '(' + x[1] + ')' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '')
//       console.log(num)
//       console.log(x[1], x[2], x[3], x[4])
//     // telInp.addEventListener('input', checkNumPhone);
//     //   return this.phone
//     }
//   }
}
</script>
<style lang="scss" scoped>

.title_form{
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #333333;
    margin-bottom: 1.125rem;
}

.contain{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.125rem;
    &__element{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 19.44rem;
        margin-right: 2.19rem;
        p{
            font-size: .875rem;
            line-height: 1.375rem;
            color: #1F3245;
            margin-bottom: .56rem;
        }
        span{
            color: #EB494E;
        }
        input{
            width: 100%;
            height: 2.1875rem;
            padding: 0 .75rem;
            background: #EEF4FA;
            border-radius: .19rem;

            font-weight: 600;
            font-size: .875rem;
            line-height: 1.38rem;
            color: #1F3245;
        }
        input::placeholder{
            font-weight: normal !important;
            color: #5A79A8 !important;
            opacity: 0.55;
        }
        input:focus{
            background: #DDEDFE;
        }
    }
    &__button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: .38rem;
        padding-top: 2.5rem;
        border-top: .063rem solid #D5D6D9;
        &__block{
            display: flex;
            align-items: center;
            .cancel{
                width: 10rem;
                padding: .5rem 0 .44rem 0;
                margin-right: 1.375rem;
                text-align: center;
                font-size: .875rem;
                line-height: 1.25rem;
                color: #246CB7;
                border: .063rem solid #246CB7;
                box-sizing: border-box;
                filter: drop-shadow(0px .375rem 1.25rem rgba(36, 108, 183, 0.35));
                border-radius: .19rem;
            }
            .footnote{
                font-size: .75rem;
                line-height: 1.125rem;
                color: #333333;
                span{
                    color: #EB494E;
                }
            }
        }
        button{
            width: 10rem;
            padding: .5rem 0 .44rem 0;
            font-weight: 600;
            font-size: .875rem;
            line-height: 1.25rem;
            text-align: center;
            color: #ffffff;
            background: #246CB7;
            border-radius: .19rem;
            opacity: 0.5;
        }
    }
}
.info_block p{
    font-size: .75rem;
    line-height: 1.125rem;
    color: #333333;
    margin-bottom: 0;
}
.error__show{
    display: flex;
    margin-bottom: 1.74rem;
    font-weight: 600;
    font-size: .813rem;
    line-height: 1.25rem;
    color: #EB494E;
    svg{
        width: 2rem;
        height: 2rem;
    }
    p{
        padding: .125rem 0 0 .38rem;
    }
}
.active-classe{
    border: .06rem solid #EB494E;
    box-sizing: border-box;
}
</style>
