<template>
    <div>
        <p class="title_form">Адрес где выявлена проблема</p>
        <div class="contain">
            <div class="contain__element">
                <p>Федеральный округ<span>*</span></p>
                <custom-select :key="`unit`" class="select" :list='getDistrictList' @InputsContent="setDistrict"  placeholder="Выберите из списка" :class="{'active-classe': activeClass && address.district ===''}"/>
                <p>Регион / край / область</p>
                <custom-select :key="`rgion`" class="select" :list='getRegionList' @InputsContent="setRegion" placeholder="Выберите из списка"/>
                <p>Город / Населенный пункт <span>*</span></p>
                <custom-select :key="`city`" class="select" :list='getCityList' @InputsContent="setCity" placeholder="Выберите из списка" :class="{'active-classe': activeClass && address.city ===''}"/>
                <p>Улица<span>*</span></p>
                <input type="text" placeholder="Введите название" v-model="address.street" :class="{'active-classe': activeClass && address.street ===''}">
                <div class="block__input">
                    <div>
                        <p>Дом<span>*</span></p>
                        <input type="text"  v-model="address.house" :class="{'active-classe': activeClass && address.house ===''}">
                    </div>
                    <div>
                        <p>Корпус</p>
                        <input type="text" v-model="address.body">
                    </div>
                    <div>
                        <p>Строение</p>
                        <input type="text" v-model="address.structure">
                    </div>
                    <div>
                        <p>Квартира</p>
                        <input type="text" v-model="address.flat">
                    </div>
                </div>
            </div>
            <div class="contain__element">
                <p>Указать на карте</p>
                <div class="map">
                    <div class="map__search">
                        <input class='input' type="text" v-model="address.coordinates" placeholder="Город, адрес, метро, район, ж/д, шоссе или ЖК" @keyup.enter="apiMemberList()">
                        <button type="button" @click="apiMemberList()" class="btn__icon btnSearch"></button>
                    </div>
                    <map-form :keyNum="keyNum"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomSelect from '@/components/forms/CustomSelect'
import MapForm from '@/components/appeal/MapForm'

export default {
  name: 'AddressProblemAppeal',
  props: {
    activeClass: {
      type: Boolean,
      define: false
    },
    keyNum: {
      typr: Number
    }
  },
  components: {
    'custom-select': CustomSelect,
    'map-form': MapForm
  },
  data () {
    return {
      address: {
        district: '',
        region: '',
        city: '',
        street: '',
        house: '',
        body: '',
        structure: '',
        flat: '',
        coordinates: ''
      },
      getDistrictList: [
        {
          title: 'Центральный'
        },
        {
          title: 'Северо-Западный'
        },
        {
          title: 'Южный'
        },
        {
          title: 'Северо-Кавказский'
        },
        {
          title: 'Приволжский'
        },
        {
          title: 'Уральский,Сибирский'
        },
        {
          title: 'Дальневосточный'
        }
      ],
      getRegionList: [
        {
          title: 'Московская область'
        },
        {
          title: 'Ленинградская область'
        },
        {
          title: 'Рязанская область'
        }
      ],
      getCityList: [
        {
          title: 'Москва'
        },
        {
          title: 'Санкт-Петербург'
        },
        {
          title: 'Рязань'
        }
      ]
    }
  },
  computed: {
    valueInput () {
      return (this.address.district || this.address.region || this.address.city || this.address.street || this.address.house ||
              this.address.body || this.address.structure || this.address.flat || this.address.coordinates)
    }
  },
  watch: {
    valueInput () {
      this.$emit('addressData', this.address)
    }
  },
  methods: {
    setDistrict (value) {
      this.address.district = value.title
    },
    setRegion (value) {
      this.address.region = value.title
    },
    setCity (value) {
      this.address.city = value.title
    }
  }
}
</script>
<style lang="scss" scoped>

.title_form{
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #333333;
    margin-bottom: 1.125rem;
}

.contain{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.125rem;
    &__element{
        display: flex;
        flex-direction: column;
        // justify-content: flex-end;
        width: 19.44rem;
        margin-right: 2.19rem;
        p{
            font-size: .875rem;
            line-height: 1.375rem;
            color: #1F3245;
            margin-bottom: .56rem;
        }
        span{
            color: #EB494E;
        }
        input{
            width: 100%;
            height: 2.1875rem;
            padding: 0 1.25rem;
            background: #EEF4FA;
            border-radius: .19rem;

            font-weight: 600;
            font-size: .875rem;
            line-height: 1.38rem;
            color: #1F3245;
        }
        input::placeholder{
            font-weight: normal !important;
            color: #003E78;
            opacity: 0.6;
            opacity: 0.55;
        }
        input:focus{
            background: #DDEDFE;
        }
        &:nth-child(2){
            width: calc(100% - 21.625rem);
            margin: 0;
        }
    }
}
.select, input{
    margin-bottom: 1.56rem;
}
.block__input{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div{
        width: 8.56rem;
    }
    & div:nth-child(3) input,
    & div:nth-child(4) input{ margin-bottom: 0;}
}
.map{
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
//     background: url('../../assets/temp/map.png') center center no-repeat;
//     background-size: contain;
}
.active-classe{
    border: .06rem solid #EB494E;
    box-sizing: border-box;
}
.map__search{
  position: absolute;
  width: 58.75rem;
  padding: 1.25rem;
  z-index: 999;

  display: flex;
  align-items: center;

  background: #FFFFFF;
  box-shadow: 0 .94rem 2.063rem rgba(37, 70, 103, 0.15);
  border-radius: .19rem;
  top: 1.88rem;
  right: 1.88rem;
  left: 1.88em;
  input{
    margin-bottom: 0;
  }
   button {
      border: none;
      position: absolute;
      right: 1.25rem;
    }
}
</style>
